import type {Selector} from "reselect";
import {createSelector} from "reselect";
import {orderBy} from "lodash";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import {serverTime} from "@atg-shared/server-time";
import type {GlobalBig9State} from "@atg-sport-shared/big9-types";
import type {
    MostPlayed,
    Offering,
    OfferingWithMatchRefs,
    OfferingWithMatches,
    OfferingsById,
} from "@atg-sport-shared/big9-types/offeringTypes";
import {OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";
import {BetSelection} from "@atg-sport-shared/big9-types/selectionTypes";
import type {MatchResult} from "@atg-sport-shared/big9-types/matchTypes";
import type {Big9RouteState} from "@atg-sport-shared/big9-types/routingState";
import {
    liveOfferingStatuses,
    preLiveOfferingStatuses,
} from "@atg-sport-shared/big9-types";
import {
    findClosestSellOpenOffering,
    findLatestFinalizedOffering,
    findLatestSellClosedOffering,
    getHasOfferingPassed,
    getIsFinalizedOrCanceledOffering,
    sortOfferings,
} from "./offeringsHelpers";

dayjs.extend(minMax);

type OfferingsWithMatchesByKey = {
    [key: string]: OfferingWithMatches;
};

// making sure we only show big9 offerings until we have migrated over to the new pool flow
const getOfferingsState = (state: GlobalBig9State) => {
    const big9ById = Object.fromEntries(
        Object.entries(state.sportsbook.big9.offerings.byId).filter(
            ([_, offering]) => offering.gameType === "BIG9",
        ),
    );

    const big9AllIds = state.sportsbook.big9.offerings.allIds.filter((offering) =>
        offering.startsWith("BIG9"),
    );

    return {
        ...state.sportsbook.big9.offerings,
        byId: big9ById,
        allIds: big9AllIds,
    };
};

const getMatchesState = (state: GlobalBig9State) => state.sportsbook.big9.matches ?? {};

export const getCompletedOfferings = createSelector(getOfferingsState, (offerings) =>
    orderBy(
        offerings.allIds
            .map((offeringId) => offerings.byId[offeringId])
            .filter(getHasOfferingPassed),
        ["startTime", "serialNumber"],
        ["desc", "desc"],
    ).slice(0, 5),
);

export const getLatestCompletedOffering = createSelector(
    getCompletedOfferings,
    (completedOfferings) => completedOfferings[0],
);

export const getFinalizedAndCanceledOfferings = createSelector(
    getOfferingsState,
    (offerings) =>
        orderBy(
            offerings.allIds
                .map((offeringId) => offerings.byId[offeringId])
                .filter(getIsFinalizedOrCanceledOffering),
            ["startTime", "serialNumber"],
            ["desc", "desc"],
        ).slice(0, 5),
);

export const getAllExceptUpcomingOfferings = createSelector(
    getOfferingsState,
    getFinalizedAndCanceledOfferings,
    (offerings, finalizedOrCanceledOfferings) => {
        const allExceptFinalizedOrCanceledOfferings = offerings.allIds
            .map((offeringId) => offerings.byId[offeringId])
            .filter((offering): boolean => !getIsFinalizedOrCanceledOffering(offering));

        const allExceptUpcomingOfferings = orderBy(
            [
                ...allExceptFinalizedOrCanceledOfferings,
                ...finalizedOrCanceledOfferings,
            ].filter((offering): boolean => offering?.status !== OfferingStatus.UPCOMING),
            ["startTime", "serialNumber"],
            ["desc", "desc"],
        );

        return allExceptUpcomingOfferings;
    },
);

export const getSellClosedOfferings = createSelector(getOfferingsState, (offerings) => {
    const ongoingOfferings = orderBy(
        offerings.allIds
            .map((offeringId) => offerings.byId[offeringId])
            .filter(
                (offering: OfferingWithMatchRefs | null): boolean =>
                    offering !== null && liveOfferingStatuses.includes(offering.status),
            ),
        ["startTime", "serialNumber"],
        ["desc", "desc"],
    );

    return ongoingOfferings.length === 0 ? null : ongoingOfferings;
});

export const getOngoingOfferings = createSelector(getOfferingsState, (offerings) => {
    const ongoingOfferings = orderBy(
        offerings.allIds
            .map((offeringId) => offerings.byId[offeringId])
            .filter(
                (offering: OfferingWithMatchRefs | null): boolean =>
                    offering !== null &&
                    preLiveOfferingStatuses.includes(offering.status),
            ),
        ["startTime", "serialNumber"],
        ["desc", "desc"],
    );

    return ongoingOfferings.length === 0 ? null : ongoingOfferings;
});

export const getClosestSellOpenOffering = (allIds?: string[], byId?: OfferingsById) => {
    if (!allIds || !byId) return null;

    const offerings = sortOfferings(allIds, byId);

    if (!offerings) return null;

    const closestSellOpenOffering = findClosestSellOpenOffering(offerings);

    return closestSellOpenOffering ?? null;
};

export const getOfferingStatusUrlPath = (status: OfferingStatus) => {
    if (preLiveOfferingStatuses.includes(status)) return "spela";
    if (liveOfferingStatuses.includes(status)) return "live";
    return "resultat";
};

export const getNextUpcomingOffering: Selector<
    GlobalBig9State,
    OfferingWithMatchRefs | null
> = createSelector(
    getOfferingsState,
    (offerings) =>
        orderBy(
            offerings.allIds.map((offeringId) => offerings.byId[offeringId]),
            ["startTime", "serialNumber"],
            ["asc", "desc"],
        ).find((offering) => offering.status === OfferingStatus.UPCOMING) ?? null,
);

export const getOfferingsById = createSelector(
    getOfferingsState,
    (offerings): OfferingsById => offerings.byId,
);

export const getOfferingById = (
    offeringId: Offering["id"],
): Selector<GlobalBig9State, OfferingWithMatchRefs> =>
    createSelector(
        getOfferingsState,
        (offerings): OfferingWithMatchRefs => offerings.byId[offeringId],
    );

export const getOfferingIds = createSelector(
    getOfferingsState,
    (offerings): string[] => offerings.allIds,
);

export const getOfferingStart = createSelector(
    getOfferingsState,
    (offerings): number | null => {
        const {startTime, day, time} = offerings;
        if (day && time) {
            const startDay = [
                "Söndag",
                "Måndag",
                "Tisdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lördag",
            ].indexOf(day);
            const date = new Date();
            const currentDay = date.getDay();
            const dayOffset = (startDay + 7 - currentDay) % 7;
            date.setDate(date.getDate() + dayOffset);
            const [hours, minutes] = time.split(":");
            date.setHours(+hours, +minutes, 0);
            return date.getTime();
        }
        return startTime ?? null;
    },
);

export const getOfferingsByIdWithMatches: Selector<
    GlobalBig9State,
    OfferingsWithMatchesByKey
> = createSelector(getOfferingsById, getMatchesState, (offerings, matches) =>
    Object.keys(offerings).reduce((previous, offeringId) => {
        const offering = offerings[offeringId];
        const {overUnderLimit} = offering;

        return {
            ...previous,
            [offeringId]: {
                ...offering,
                matches: offering.matches.map(({id, number}) => {
                    const match = matches[id];

                    if (!match) return {};

                    return {
                        ...match,
                        matchId: id,
                        number,
                        overUnderLimit,
                        homeTeamName: match.homeTeam.shortName,
                        awayTeamName: match.awayTeam.shortName,
                    };
                }),
            },
        };
    }, {}),
);

export const getOfferingByPathname = createSelector(
    (state: GlobalBig9State) => state.router?.location?.pathname,
    getOfferingIds,
    getOfferingsById,
    (pathname, allIds, byId) => {
        const offerings = sortOfferings(allIds, byId);

        if (!offerings) return null;

        const useFinalizedOffering = pathname?.endsWith("/resultat");

        if (useFinalizedOffering) {
            const latestFinalizedOffering = findLatestFinalizedOffering(offerings);

            if (latestFinalizedOffering) return latestFinalizedOffering;
        }

        const useSellClosedOffering = pathname?.endsWith("/live");

        if (useSellClosedOffering) {
            const latestSellClosedOffering = findLatestSellClosedOffering(offerings);

            if (latestSellClosedOffering) return latestSellClosedOffering;
        }

        const closestSellOpenOffering = findClosestSellOpenOffering(offerings);

        return closestSellOpenOffering ?? offerings[0];
    },
);

export const getHeroOffering = createSelector(
    getOfferingIds,
    getOfferingsById,
    (allIds?: string[], byId?: OfferingsById) => {
        const offerings = sortOfferings(allIds, byId);

        if (!offerings) return null;

        const sellClosedOffering = findLatestSellClosedOffering(offerings);

        if (sellClosedOffering) return sellClosedOffering;

        const now = serverTime();

        const finalizedOffering = findLatestFinalizedOffering(offerings);

        if (finalizedOffering) {
            const latestMatchStartTime = dayjs.max(
                finalizedOffering.matches.map(({startTime}) => dayjs(startTime)),
            );

            // show offering until 1AM the day after the last match is completed
            const hideOfferingTime = latestMatchStartTime?.add(1, "day").hour(1);

            if (now.isBefore(hideOfferingTime)) {
                return finalizedOffering;
            }
        }

        const sellOpenOfferings = findClosestSellOpenOffering(offerings);

        return sellOpenOfferings ?? finalizedOffering;
    },
);
export const getOverviewOffering = createSelector(
    getOfferingIds,
    getOfferingsById,
    (allIds?: string[], byId?: OfferingsById) => {
        const offerings = sortOfferings(allIds, byId);

        if (!offerings) return null;

        const latestSellClosedOffering = findLatestSellClosedOffering(offerings);

        if (latestSellClosedOffering) return latestSellClosedOffering;

        const closestSellOpenOffering = findClosestSellOpenOffering(offerings);

        if (closestSellOpenOffering) return closestSellOpenOffering;

        const latestFinalizedOffering = findLatestFinalizedOffering(offerings);

        return latestFinalizedOffering ?? null;
    },
);

const getClosestOfferingByStartTime = (
    offerings: OfferingWithMatchRefs[],
    compareTime: dayjs.Dayjs,
) => {
    if (!offerings.length) return null;
    return offerings.reduce<OfferingWithMatchRefs | null>((prev, curr) => {
        if (!prev) return curr;
        const currTime = dayjs(curr.startTime);
        const prevTime = dayjs(prev.startTime);

        return Math.abs(currTime.diff(compareTime)) < Math.abs(prevTime.diff(compareTime))
            ? curr
            : prev;
    }, null);
};

export const getDefaultOffering = createSelector(
    getAllExceptUpcomingOfferings,
    (offerings) => {
        if (!offerings) return null;

        const now = serverTime();

        const closestLiveOffering = getClosestOfferingByStartTime(
            offerings.filter((offering) =>
                liveOfferingStatuses.includes(offering.status),
            ),
            now,
        );

        if (closestLiveOffering) {
            return closestLiveOffering;
        }

        const closestFinalizedOffering = getClosestOfferingByStartTime(
            offerings.filter((offering) => offering.status === OfferingStatus.FINALIZED),
            now,
        );

        if (closestFinalizedOffering) {
            const latestMatchStartTime = dayjs.max(
                closestFinalizedOffering.matches.map(({startTime}) => dayjs(startTime)),
            );

            // show offering until 1AM the day after the last match is completed
            const hideOfferingTime = latestMatchStartTime?.add(1, "day").hour(1);

            if (now.isBefore(hideOfferingTime)) {
                return closestFinalizedOffering;
            }
        }

        const closestOpenOffering = getClosestOfferingByStartTime(
            offerings.filter((offering) =>
                preLiveOfferingStatuses.includes(offering.status),
            ),
            now,
        );

        if (closestOpenOffering) {
            return closestOpenOffering;
        }

        return closestFinalizedOffering;
    },
);

const getOfferingByRoute = createSelector(
    (state: GlobalBig9State) =>
        (state.router?.location.state as Big9RouteState)?.offeringId,
    getOfferingsById,
    (id, offerings) => id && offerings[id],
);

export const getOffering = createSelector(
    getOfferingByRoute,
    getOfferingByPathname,
    (route, pathname) => route || pathname,
);

export const createOfferingSelector = (
    offeringId: string,
): Selector<GlobalBig9State, OfferingWithMatchRefs | null> =>
    createSelector(getOfferingsById, (offerings) => offerings[offeringId]);

const getMatchesStateSelector = (state: GlobalBig9State) => getMatchesState(state);

export const getOfferingWithMatches = (offeringId: string) =>
    createSelector(
        createOfferingSelector(offeringId),
        getMatchesStateSelector,
        (offering, matches) => {
            if (!offering) return null;

            const {overUnderLimit} = offering;

            return {
                ...offering,
                matches: offering.matches.map(({id, number}) => {
                    const match = matches[id];
                    return {
                        ...match,
                        matchId: id,
                        number,
                        overUnderLimit,
                        homeTeamName: match.homeTeam.shortName,
                        homeTeamAbbreviation: match.homeTeam.abbreviation,
                        awayTeamName: match.awayTeam.shortName,
                        awayTeamAbbreviation: match.awayTeam.abbreviation,
                    };
                }),
            };
        },
    );

// Exported for reuse in /jackpot/start
export const findCurrentOffering = (allIds?: string[], byId?: OfferingsById) => {
    if (!allIds || !byId) return null;

    const offerings = orderBy(
        allIds.map((id) => byId[id]),
        ["startTime", "serialNumber"],
        ["desc", "desc"],
    ).filter((offering) => offering.status !== OfferingStatus.UPCOMING);

    return (offerings && offerings[0]) ?? null;
};

export const getCurrentOffering = createSelector(
    getOfferingIds,
    getOfferingsById,
    findCurrentOffering,
);

export const getOfferingId = createSelector(
    getOffering,
    (offering) => (offering && offering?.id) || "",
);

export const getCurrentOfferingId = createSelector(
    getCurrentOffering,
    (offering) => (offering as OfferingWithMatchRefs)?.id ?? null,
);

export const getOverUnderLimit = (state: GlobalBig9State, offeringId: string): number =>
    state.sportsbook.big9.offerings.byId[offeringId]?.overUnderLimit;

export const getOfferingStatus: (
    offeringId: string,
) => (state: GlobalBig9State) => OfferingStatus | null =
    (offeringId: string) => (state: GlobalBig9State) =>
        createOfferingSelector(offeringId)(state)?.status ?? null;

export const getOfferingStartTime: (
    offeringId: string,
) => (state: GlobalBig9State) => string | null =
    (offeringId: string) => (state: GlobalBig9State) =>
        createOfferingSelector(offeringId)(state)?.startTime ?? null;

export const getOfferingJackpot: (
    offeringId?: string,
) => (state: GlobalBig9State) => number | null =
    (offeringId?: string) => (state: GlobalBig9State) => {
        if (!offeringId) return null;

        const offering = createOfferingSelector(offeringId)(state);
        const expectedJackpot = offering?.pool?.expectedJackpot;
        return expectedJackpot ? expectedJackpot / 100 : null;
    };

export const getCurrentOfferingCanceledOrPaused = (): Selector<
    GlobalBig9State,
    boolean
> =>
    createSelector(getCurrentOffering, (offering) =>
        [OfferingStatus.CANCELED, OfferingStatus.UNDER_REVIEW].includes(
            offering?.status || ("" as OfferingStatus),
        ),
    );

export const getOfferingCanceledOrPaused =
    (offeringId: string) => (state: GlobalBig9State) => {
        const offering = createOfferingSelector(offeringId)(state);
        return [OfferingStatus.CANCELED, OfferingStatus.UNDER_REVIEW].includes(
            offering?.status || ("" as OfferingStatus),
        );
    };

export const isOfferingsLoading = (state: GlobalBig9State) =>
    fetchSelectors.isLoading(getOfferingsState(state));
export const isOfferingsLoaded = (state: GlobalBig9State) =>
    fetchSelectors.isLoaded(getOfferingsState(state));
export const hasOfferingsError = (state: GlobalBig9State) =>
    fetchSelectors.hasError(getOfferingsState(state));

export const createMostPlayedSelector = (
    offeringId: string,
): Selector<GlobalBig9State, MostPlayed | null> =>
    createSelector(getOfferingsByIdWithMatches, (offering) => {
        if (!offering[offeringId]) return null;
        const {matches} = offering[offeringId];

        const matchValues = Object.values(matches);

        if (!matchValues.some((match) => match.betDistribution)) return null;

        const matchesMap = matchValues.map(
            ({betDistribution, homeTeam, awayTeam, number}) => {
                const {wildcard, ...rest} = betDistribution ?? {};
                // Most played fulltime result of match
                const winner = Object.entries(rest).reduce(
                    (acc, [key, value]) =>
                        value.over + value.under > acc.percentage
                            ? {
                                  percentage: value.over + value.under,
                                  selection: key as MatchResult["winner"],
                              }
                            : acc,
                    {
                        percentage: 0,
                        selection: BetSelection.Home as MatchResult["winner"],
                    },
                );
                // Most played over/under outcome of match
                const overUnder = Object.values(rest).reduce(
                    (acc, value) => ({
                        over: {...acc.over, percentage: acc.over.percentage + value.over},
                        under: {
                            ...acc.under,
                            percentage: acc.under.percentage + value.under,
                        },
                    }),
                    {
                        over: {
                            percentage: 0,
                            selection: BetSelection.Over as MatchResult["goals"],
                        },
                        under: {
                            percentage: 0,
                            selection: BetSelection.Under as MatchResult["goals"],
                        },
                    },
                );
                const goal: {percentage: number; selection: MatchResult["goals"]} =
                    overUnder.over.percentage > overUnder.under.percentage
                        ? overUnder.over
                        : overUnder.under;
                return {winner, goal, wildcard, homeTeam, awayTeam, matchNumber: number};
            },
        );
        const winners = orderBy(matchesMap, (match) => match.winner.percentage, ["desc"])
            .slice(0, 2)
            .map(
                ({winner: {percentage, ...winner}, homeTeam, awayTeam, matchNumber}) => ({
                    ...winner,
                    percentage: Math.round(percentage),
                    homeTeam,
                    awayTeam,
                    matchNumber,
                }),
            );
        const goals = orderBy(matchesMap, (match) => match.goal.percentage, ["desc"])
            .slice(0, 2)
            .map(({goal: {percentage, ...goal}, homeTeam, awayTeam, matchNumber}) => ({
                ...goal,
                percentage: Math.round(percentage),
                homeTeam,
                awayTeam,
                matchNumber,
            }));
        const wildcards = orderBy(matchesMap, (match) => match.wildcard, ["desc"])
            .slice(0, 2)
            .map(({homeTeam, awayTeam, matchNumber, wildcard}) => ({
                percentage: Math.round(wildcard ?? 0),
                homeTeam,
                awayTeam,
                matchNumber,
            }));

        return {winners, goals, wildcards};
    });
