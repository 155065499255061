/* eslint-disable no-nested-ternary */
import type {SagaIterator} from "redux-saga";
// @ts-ignore
import root from "window-or-global";
import {get} from "lodash";
import {isApp} from "@atg-shared/system";
import {fetchAuthorized} from "@atg-shared/auth";
import {
    BIG9_COUPON_API_URL,
    BIG9_OFFERING_API_URL,
    BIG9_PLACE_BET_API_URL,
    BIG9_PLACE_BET_V2_API_URL,
    BIG9_RECEIPTS_V2_API_URL,
    atgApiSubdomain,
} from "@atg-shared/service-url";
import atgFetch, {pureFetch} from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import type {
    ApiCoupon,
    Coupon as NewCoupon,
    PlaceBetCoupon,
} from "@atg-sport-shared/big9-types/couponTypes";
import type {StatisticsState} from "@atg-sport-shared/big9-types/statisticsTypes";
import type {
    HarryBetBody,
    PlaceBetBody,
} from "@atg-sport-shared/big9-types/apiTypes/betTypes";
import type {Offering, OfferingStatus} from "@atg-sport-shared/big9-types/offeringTypes";
import type {Bet, Receipt} from "@atg-sport-shared/big9-types/betTypes";
import type {MatchComments} from "@atg-sport-shared/big9-types/matchTypes";

export const SHARED_COUPON_API_URL = `https://${atgApiSubdomain}.atg.se/sports-coupon/api-public/v1/coupons`;

type SagaIteratorOrPromise<T> = SagaIterator<T> | Promise<T>;

type FetchOrMockParams = {
    fetchParams: {
        url: string;
        requestOptions?: any;
    };
    requireLogin: boolean;
    pure?: boolean;
};

const fetchFunction = <T>({
    fetchParams,
    requireLogin,
    pure = true,
}: FetchOrMockParams): SagaIterator<AtgResponse<T>> | Promise<AtgResponse<T>> =>
    requireLogin
        ? fetchAuthorized(fetchParams.url, fetchParams.requestOptions, {
              memberFlowEnabled: false,
          })
        : pure
        ? pureFetch(fetchParams.url, fetchParams.requestOptions)
        : atgFetch(fetchParams.url, fetchParams.requestOptions);

export const fetchOfferings = ({
    statuses,
    fromDate,
}: {
    statuses: ReadonlyArray<OfferingStatus>;
    fromDate: string;
}): SagaIteratorOrPromise<AtgResponse<Array<Offering>>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: `${BIG9_OFFERING_API_URL}?status=${
                statuses.length > 1 ? statuses.join(",") : statuses[0]
            }&fromDate=${fromDate}&type=BIG9`,
        },
    });

export const fetchOffering = ({
    offeringId,
}: {
    offeringId: string;
}): SagaIteratorOrPromise<AtgResponse<Offering>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {url: `${BIG9_OFFERING_API_URL}/${offeringId}`},
    });

export const fetchCoupons = (): SagaIteratorOrPromise<AtgResponse<ApiCoupon[]>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {url: BIG9_COUPON_API_URL},
    });

export const fetchSharedCoupon = (
    couponId: string,
): SagaIteratorOrPromise<AtgResponse<ApiCoupon>> =>
    fetchFunction({
        requireLogin: false,
        fetchParams: {
            url: `${SHARED_COUPON_API_URL}/${couponId}`,
            requestOptions: isApp
                ? {
                      headers: {origin: "https://www.atg.se"},
                  }
                : undefined,
        },
    });

export const placeBet = ({
    body,
    businessOperationId,
}: {
    body: PlaceBetBody;
    businessOperationId?: string;
}): SagaIteratorOrPromise<AtgResponse<Bet>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: BIG9_PLACE_BET_API_URL,
            requestOptions: {
                method: "POST",
                body: JSON.stringify(body),
                ...(businessOperationId ? {headers: {businessOperationId}} : undefined),
            },
        },
    });

export const placeBetV2 = ({
    body,
    businessOperationId,
    signal,
}: {
    body: PlaceBetCoupon | HarryBetBody;
    businessOperationId?: string;
    signal?: AbortSignal;
}): SagaIteratorOrPromise<AtgResponse<Bet>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: BIG9_PLACE_BET_V2_API_URL,
            requestOptions: {
                method: "POST",
                body: JSON.stringify(body),
                ...(businessOperationId ? {headers: {businessOperationId}} : undefined),
                signal,
            },
        },
    });

export const fetchBets = ({
    placedAtFrom,
    placedAtTo,
}: {
    placedAtFrom: string;
    placedAtTo: string;
}): SagaIteratorOrPromise<
    AtgResponse<{
        offerings: {[ID in Offering["id"]]: Offering};
        receipts: ReadonlyArray<Receipt>;
    }>
> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: `${BIG9_RECEIPTS_V2_API_URL}?placedAtFrom=${placedAtFrom}&placedAtTo=${placedAtTo}`,
        },
    });

export const fetchPreMatchStatistics = (
    offeringId: string,
): SagaIteratorOrPromise<AtgResponse<StatisticsState>> =>
    fetchFunction({
        requireLogin: false,
        fetchParams: {
            url: `${get(
                root,
                "clientConfig.sport.big9PreMatchStatistics",
            )}/${offeringId}`,
        },
    });

export const createCoupon = (
    body: ApiCoupon | NewCoupon,
): SagaIteratorOrPromise<AtgResponse<ApiCoupon>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: BIG9_COUPON_API_URL,
            requestOptions: {
                method: "POST",
                body: JSON.stringify(body),
            },
        },
    });

export const deleteCoupon = (
    couponId: string,
): SagaIteratorOrPromise<AtgResponse<string>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: `${BIG9_COUPON_API_URL}/${couponId}`,
            requestOptions: {
                method: "DELETE",
            },
        },
    });

export const saveCoupon = (
    body: ApiCoupon | NewCoupon,
): SagaIteratorOrPromise<AtgResponse<ApiCoupon>> =>
    fetchFunction({
        requireLogin: true,
        fetchParams: {
            url: `${BIG9_COUPON_API_URL}/${body.id}`,
            requestOptions: {
                method: "PUT",
                body: JSON.stringify(body),
            },
        },
    });

export const fetchMatchComments = (): SagaIteratorOrPromise<AtgResponse<MatchComments>> =>
    fetchFunction({
        requireLogin: false,
        fetchParams: {
            url: `${get(
                root,
                "clientConfig.sport.cloudfrontUrl",
            )}/content/big9MatchComments`,
        },
        pure: true,
    });

export const fetchOfferingStart = (): SagaIteratorOrPromise<AtgResponse<any>> =>
    fetchFunction({
        requireLogin: false,
        fetchParams: {
            url: `${get(
                root,
                "clientConfig.sport.cloudfrontUrl",
            )}/content/big9OfferingStart.json`,
        },
        pure: true,
    });
