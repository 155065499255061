import type {Match, MatchNumber} from "./matchTypes";
import type {Offering} from "./offeringTypes";
import type {AdvancedSelectionV2, OutcomeSimpleSelection} from "./outcomeTypes";

export enum GameType {
    Big9 = "BIG9",
}

export enum BetStatus {
    Active = "ACTIVE",
    Graded = "GRADED",
    GradingRevoked = "GRADING_REVOKED",
    Refunded = "REFUNDED",
    Canceled = "CANCELED",
}

export enum BetChannel {
    WEB_TILLSAMMANS = "WEB_TILLSAMMANS",
    WEB_ATGSE = "WEB_ATGSE",
    APP_IOS = "APP_IOS",
    APP_ANDROID = "APP_ANDROID",
    RETAIL_TERMINAL = "RETAIL_TERMINAL",
    AGENT_TERMINAL = "AGENT_TERMINAL",
    UNKNOWN = "UNKNOWN",
}

export enum BetSummary {
    COUPON_TEAM = "COUPON_TEAM",
    FILE_BET = "FILE_BET",
    FLEX = "FLEX",
    HARRY = "HARRY",
    SHOP = "SHOP",
    TILLSAMMANS = "TILLSAMMANS",
}

export enum HarryType {
    Flex = "flex",
    Simple = "simple",
}

export type MatchKey = MatchNumber;

export type BetMatch = Omit<Match, "id"> & {
    matchId: string;
    betId: string;
    overUnderLimit: number;
    homeTeamAbbreviation: string;
    awayTeamAbbreviation: string;
};

export type Selections = {[MK in MatchKey]: OutcomeSimpleSelection};

export type AdvancedSelections = {[MK in MatchKey]: AdvancedSelectionV2};

export type BetSelections = {
    [MK in MatchKey]: {
        simple: OutcomeSimpleSelection;
        advanced: AdvancedSelectionV2;
    };
};

export enum BetMode {
    Simple = "SIMPLE",
    Advanced = "ADVANCED",
}

export type BetCouponMatch = {
    id: string | null;
    number: MatchNumber;
    selections: OutcomeSimpleSelection;
    advancedSelections: AdvancedSelectionV2;
};

export type BetCoupon = {
    // Is this used??
    type?: string;
    stakePerCombination?: number;
    totalStake: number;
    matches: ReadonlyArray<BetCouponMatch>;
    mode: BetMode;
};

export type AdvancedOutcomes = "1/O" | "1/U" | "X/O" | "X/U" | "2/O" | "2/U" | "*";

export type BetSelectionsV2 = {
    mode: BetMode;
    legs: {[nr in MatchNumber]: ReadonlyArray<AdvancedOutcomes>};
};

export type BetCouponV2 = {
    selections: BetSelectionsV2;
    nominalStake: number;
    stake: number;
    stakePerCombination: number;
};

export type GradingKey = "9" | "8" | "7";

export type Grading = {[GK in GradingKey]: number};

export type Bet = {
    type: string;
    channel?: BetChannel;
    gradingCode?: string;
    placedAt?: string;
    reference: string;
    tsn: string; // betId
    status: BetStatus;
    cost: number;
    fees: number;
    stake: number;
    grading?: Grading;
    payout?: number;
    // Wont be set by backend for andelsspel/tillsammansspel
    refund?: number;
    coupons: ReadonlyArray<BetCoupon>;
};

export type BetV2 = {
    type: string;
    channel?: BetChannel;
    gradingCode?: string;
    placedAt?: string;
    reference: string;
    tsn: string; // betId
    status: BetStatus;
    cost: number;
    fees: number;
    stake: number;
    grading?: Grading;
    payout?: number;
    // Wont be set by backend for andelsspel/tillsammansspel
    refund?: number;
    canceledLegs?: ReadonlyArray<MatchNumber>;
    coupons: ReadonlyArray<BetCouponV2>;
};

export enum ShareType {
    TILLSAMMANS = "TILLSAMMANS",
    SHOP = "SHOP",
    COUPON_TEAM = "COUPON_TEAM",
}

export type ShareInfo = {
    sharedBetId: string;
    offeringId: string;
    name: string;
    shopInfo: {
        name: string;
        slug: string;
    };
    shareType: string;
    boughtAt: string;
    sharePrice: number;
    type: ShareType;
    price: number;
    lastBoughtAt: string;
    nrOfShares: {
        my: number;
        total?: number;
    };
    cost: {
        my: number;
        total?: number;
        fee?: number;
    };
    winnings?: {
        my: number;
        total: number;
        perShare: number;
        perSystem: number | null;
    };
    salesChannel?: string;
    channel: BetChannel;
};

export type Receipt = {
    // `bets` can be an empty array.
    // This happens for preliminary receipts,
    // such as bought shares (andelar) and tillsammans receipts,
    // that hasn't any placed bet just yet.
    bets: ReadonlyArray<Bet>;
    offeringId: string;
    summary: {
        harry: boolean;
        filebet: boolean;
        shop: boolean;
        tillsammans: boolean;
        couponTeam: boolean;
        flex: boolean;
    };
    shares: ShareInfo | null;
};

export type ReceiptV2 = {
    // `bets` can be an empty array.
    // This happens for preliminary receipts,
    // such as bought shares (andelar) and tillsammans receipts,
    // that hasn't any placed bet just yet.
    bets: ReadonlyArray<BetV2>;
    offeringId: string;
    summary: {
        harry: boolean;
        filebet: boolean;
        shop: boolean;
        tillsammans: boolean;
        couponTeam: boolean;
        flex: boolean;
    };
    shares: ShareInfo | null;
};

export type OfferingsAndReceiptsV2Response = {
    offerings: {[x: string]: Offering};
    receipts: ReadonlyArray<ReceiptV2>;
};

export type OfferingsAndReceiptsResponse = {
    offerings: {[x: string]: Offering};
    receipts: ReadonlyArray<Receipt>;
};

export type ReceiptAndOffering = {
    offerings: {[ID in Offering["id"]]: Offering};
    receipts: {[TSN in Bet["tsn"]]: Receipt};
};

export type BetsState = {
    data: {
        offerings: ReceiptAndOffering["offerings"];
        receipts: ReceiptAndOffering["receipts"];
        lastPlacedAtFrom: string | null;
        lastPlacedAtTo: string | null;
        isFetchingMore: boolean;
        isLastPageReached: boolean;
        isPolling: boolean;
    };
    correctedBets: {[betId: string]: string}; // value is a date, like 2021-04-01
    betRevealedRows: {[betId: string]: ReadonlyArray<boolean>};
    highlightedBet: string | null;
    highlightedOfferingBets: {[offeringId: string]: string | null};
};
