import type {FetchAction} from "@atg-shared/fetch-types";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
import type {State} from "./index";

export const REQUEST_SHOP_SHARE = "shop/REQUEST_SHOP_SHARE";
export const RECEIVE_SHOP_SHARE = "shop/RECEIVE_SHOP_SHARE";

export const PURCHASE_SHOP_SHARE = "shop/PURCHASE_SHOP_SHARE";
export const RESET_SHOP_SHARE_STATUS = "shop/RESET_SHOP_SHARE_STATUS";

export const SHOP_SHARE_COUPON_DETAILS_REQUEST = "shop/SHOP_SHARE_COUPON_DETAILS_REQUEST";
export const SHOP_SHARE_COUPON_DETAILS_RESPONSE =
    "shop/SHOP_SHARE_COUPON_DETAILS_RESPONSE";
export const SHOP_SHARE_COUPON_DETAILS_RESET = "shop/SHOP_SHARE_COUPON_DETAILS_RESET";

export const CREATE_SHARE_REQUEST = "shopShare/CREATE_SHARE_REQUEST";
export const CREATE_SHARE_RESPONSE = "shopShare/CREATE_SHARE_RESPONSE";
export const CREATE_SHARE_RESET = "shopShare/CREATE_SHARE_RESET";

export const SET_GAME_INITIATOR_REQUEST = "shopShare/SET_GAME_INITIATOR_REQUEST";
export const SET_GAME_INITIATOR_RESPONSE = "shopShare/SET_GAME_INITIATOR_RESPONSE";
export const SET_GAME_INITIATOR_RESET = "shopShare/SET_GAME_INITIATOR_RESET";

export const SET_COUPON_ID = "shopShare/SET_COUPON_ID";

export const SHOP_SHARE_COUPON_VISIBILITY_REQUEST = "shopShare/COUPON_VISIBILITY_REQUEST";
export const SHOP_SHARE_COUPON_VISIBILITY_RESPONSE =
    "shopShare/COUPON_VISIBILITY_RESPONSE";
export const SHOP_SHARE_COUPON_VISIBILITY_RESET = "shopShare/COUPON_VISIBILITY_RESET";

export const UPDATE_SHOP_SHARE_REQUEST = "shopShare/UPDATE_SHOP_SHARE_REQUEST";
export const UPDATE_SHOP_SHARE_RESPONSE = "shopShare/UPDATE_SHOP_SHARE_RESPONSE";
export const UPDATE_SHOP_SHARE_RESET = "shopShare/UPDATE_SHOP_SHARE_RESET";

export const DELETE_SHOP_SHARE_REQUEST = "shopShare/DELETE_SHOP_SHARE_REQUEST";
export const DELETE_SHOP_SHARE_RESPONSE = "shopShare/DELETE_SHOP_SHARE_RESPONSE";
export const DELETE_SHOP_SHARE_RESET = "shopShare/DELETE_SHOP_SHARE_RESET";

export const EDIT_SHARE_NAME_REQUEST = "shareRound/EDIT_NAME_REQUEST";
export const EDIT_SHARE_NAME_RESPONSE = "shareRound/EDIT_NAME_RESPONSE";
export const EDIT_SHARE_NAME_RESET = "shopShare/EDIT_SHARE_NAME_RESET";

export const SET_RECEIPT_RESULT = "shopShare/SET_RECEIPT_RESULT";

export const SET_RECEIPT = "shopShare/SET_RECEIPT";

export type ShopShareSearchResponse = {
    shares: Array<ShopShare>;
    totalNumberOfHits: number;
};

export type CreateShareResponse = {shareId: string};

export type CreateSharePayload = {
    gameType: string;
    name: string;
    gameInitiatorId: number;
    gameId: string;
};

export type SetGameInitiatorPayload = {
    gameInitiatorId: number;
};

export type EditShareNamePayload = {
    shareId: string;
    shareName: string;
};

export type ResetCreateShareAction = {
    type: typeof CREATE_SHARE_RESET;
};

export type CreateShareAction = FetchAction<
    typeof CREATE_SHARE_REQUEST,
    typeof CREATE_SHARE_RESPONSE,
    CreateShareResponse,
    State
>;

export type ResetUpdateShareAction = {
    type: typeof UPDATE_SHOP_SHARE_RESET;
};

export type DeleteUpdateShareAction = {
    type: typeof DELETE_SHOP_SHARE_RESET;
};

export type ResetSetGameInitiatorAction = {
    type: typeof SET_GAME_INITIATOR_RESET;
};

export type EditShareNameAction = FetchAction<
    typeof EDIT_SHARE_NAME_REQUEST,
    typeof EDIT_SHARE_NAME_RESPONSE,
    ShopShare,
    State
>;

export type ResetEditGameAction = {
    type: typeof EDIT_SHARE_NAME_RESET;
};

export type SetCouponIdAction = {
    type: typeof SET_COUPON_ID;
    payload: {couponId: string};
};

export type SetGameInitiatorAction = FetchAction<
    typeof SET_GAME_INITIATOR_REQUEST,
    typeof SET_GAME_INITIATOR_RESPONSE,
    State
>;

export type ShopShareFetchAction = FetchAction<
    typeof REQUEST_SHOP_SHARE,
    typeof RECEIVE_SHOP_SHARE,
    ShopShare,
    State
>;

export type ShopShareCouponVisibilityAction = FetchAction<
    typeof SHOP_SHARE_COUPON_VISIBILITY_REQUEST,
    typeof SHOP_SHARE_COUPON_VISIBILITY_RESPONSE,
    State,
    {isCouponPublic: boolean; couponId: string}
>;

export type ShopShareCouponVisibilityResetAction = {
    type: typeof SHOP_SHARE_COUPON_VISIBILITY_RESET;
};
export type ShopShareResetAction = {
    type: typeof RESET_SHOP_SHARE_STATUS;
};

export type ShopSharePurchaseAction = {
    type: typeof PURCHASE_SHOP_SHARE;
    payload: ShopShare;
};

export type ShopShareCouponDetailsFetchAction = FetchAction<
    typeof SHOP_SHARE_COUPON_DETAILS_REQUEST,
    typeof SHOP_SHARE_COUPON_DETAILS_RESPONSE,
    ShopShare,
    State,
    {couponId: string}
>;

export type ShopShareCouponDetailsResetAction = {
    type: typeof SHOP_SHARE_COUPON_DETAILS_RESET;
};

export type ShopShareDeleteAction = FetchAction<
    typeof DELETE_SHOP_SHARE_REQUEST,
    typeof DELETE_SHOP_SHARE_RESPONSE,
    State
>;

export type SetReceiptResultAction = {
    type: typeof SET_RECEIPT_RESULT;
    payload: {
        message: string;
    };
};

export type SetReceiptAction = {
    type: typeof SET_RECEIPT;
    payload: {
        message: string;
    };
};

export type Action =
    | ShopShareFetchAction
    | ShopShareResetAction
    | ShopSharePurchaseAction
    | ShopShareCouponDetailsFetchAction
    | ShopShareCouponDetailsResetAction
    | ShopShareCouponVisibilityAction
    | ShopShareCouponVisibilityResetAction
    | ShopShareDeleteAction
    | CreateShareAction
    | ResetCreateShareAction
    | SetGameInitiatorAction
    | SetCouponIdAction
    | ResetSetGameInitiatorAction
    | EditShareNameAction
    | SetReceiptResultAction
    | SetReceiptAction;
